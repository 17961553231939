import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { getAnimationSelectionTableLot, getInSideRespin, getOutSideRespin } from '../anticipation';
import {
  AnimationType,
  baseGameGoldSevenReadyStopBelowReel,
  baseGameRedSevenReadyStopBelowReel,
  baseGameSevenNotRespinBelowReel,
  baseGameSevenNotRespinOnReel,
  baseGameSevenReadyRespinStopOnReel,
  baseGameSevenWinMode12,
  baseGameSevenWinMode345,
  respinLevel4SevenWinMode345,
} from '../anticipation/table';
import { ISongs, SlotId } from '../config';
import {
  BetBonusReward,
  BetReward,
  BgmSoundTypes,
  EventTypes,
  GameMode,
  ISettledBet,
  ReelAnticipation,
  Round12345,
  RoundType,
  UserBonus,
  reelSets,
} from '../global.d';
import {
  setAnticipationLine,
  setAnticipationSlotId,
  setBattleBonusRounds,
  setBrokenGame,
  setBrokenGameReelSet,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setHyperBattleBonus,
  setIsBattleStartAnim,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeRoundBonus,
  setIsFreeSpinsWin,
  setIsGetUserBonuses,
  setIsHyperBattleBonus,
  setIsRevokeThrowingError,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setReelAnticipation,
  setReelSetId,
  setRespinBusy,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { ReelSetType, isStoppedGql } from '../gql/query';
import {
  formatNumber,
  getGameModeByBonusId,
  getSpinResult3x3,
  getStopReel,
  isBaseGameMode,
  isBattleBonusMode,
  isBuyFeatureMode,
  isFreeSpinsMode,
  isReSpinMode,
  isReSpinSlotID,
  normalizeCoins,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BaseGameCharaWindow from './baseGameCharaWindow/baseGameCharaWindow';
import BattleBnsUpperBackgroundContainer from './battleBonus/background/battleBnsUpperBackgroundContainer';
import BattleBonusContainer, { BattleAnim } from './battleBonus/battleBonusContainer';
import BgmControl from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  ANTICIPATION_RESPIN_REEL,
  ANTICIPATION_START_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  BB_BANNER_FADEOUT_TIME,
  BB_TRIGGER_DELAY,
  BONUS_SYMBOLS_ID,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  GOLD_BONUS_SYMBOLS_ID,
  HBB_TRIGGER_DELAY,
  RED_BONUS_SYMBOLS_ID,
  REELS_AMOUNT,
  RESPIN1_QUICK_START_WAITE,
  RESPIN_OTHER_QUICK_START_WAITE,
  RESPIN_WAIT_TIME,
  SP_ENDING_ROUND,
  SlotMachineState,
  WIN_ANIM_START_DELAY,
  eventManager,
} from './config';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import { getUserActiveBonuses } from './freeRoundBonus/helper';
import GameView from './gameView/gameView';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import Phoenix from './phoenix/phoenix';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsStopDisplayContainer } from './winAnimations/slotsStopDisplayContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  private battleBonusCounter = 0;

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public battleBnsUpperBackgroundContainer: BattleBnsUpperBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public slotsStopDisplayContainer: SlotsStopDisplayContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  private phoenix: Phoenix;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public infoBuyFeatureIcon: PIXI.Container;

  public baseGameCharaWindow: BaseGameCharaWindow;

  public battleBonusContainer: BattleBonusContainer;

  private isGold: boolean;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    this.battleBnsUpperBackgroundContainer = new BattleBnsUpperBackgroundContainer();
    // todo add if bonus logic

    let startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    let reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;

    if (startPosition.length === 0) {
      startPosition = [0, 0, 0];
      reelSet = slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;
    }
    if (setBrokenGame()) {
      startPosition = setPrevReelsPosition();
      reelSet = slotConfig.reels.find((reelSet) => reelSet.id === setBrokenGameReelSet())!;
    }
    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));

    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult3x3({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });

    this.slotsStopDisplayContainer = new SlotsStopDisplayContainer(spinResult);
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);

    this.miniPayTableContainer = new MiniPayTableContainer(slotConfig.icons, this.getSlotById.bind(this));
    this.miniPayTableContainer.setSpinResult(spinResult);

    this.background = new Background();
    this.application.stage.addChild(this.background);

    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.baseGameCharaWindow = new BaseGameCharaWindow();
    this.battleBonusContainer = new BattleBonusContainer();
    this.gameView = new GameView({
      winSlotsContainer: new WinSlotsContainer(),
      slotStopDisplayContainer: this.slotsStopDisplayContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      battleBnsUpperBackgroundContainer: this.battleBnsUpperBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
      baseGameCharaWindow: this.baseGameCharaWindow,
      battleBonusContainer: this.battleBonusContainer,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });
    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);

    // TODO
    // if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
    this.initBuyFeature(slotConfig.lines, this.gameView);
    // }
    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      this.onBrokenGame();
    }

    this.phoenix = new Phoenix();
    this.application.stage.addChild(this.phoenix);

    if (isReSpinMode(setGameMode())) {
      eventManager.emit(EventTypes.CREATE_RESPIN_MESSAGE);
      this.setState(SlotMachineState.IDLE);
    }

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();

    this.isGold = false;
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(new BuyFeatureBtn(), new Backdrop(), new BuyFeaturePopup(lines), new BuyFeaturePopupConfirm());
  }

  private onBrokenGame(): void {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonus.id);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });
    eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);

    if (setCurrentFreeSpinsTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }
    eventManager.emit(EventTypes.CREATE_BATTLE_BNS_TITLE, {
      text: i18n.t('battleBonusTitleText'),
      currentSet: Math.floor(setCurrentBonus().totalRounds / 6) + 1,
    });

    const currentRound = Math.floor(setCurrentBonus().totalRounds / 6);
    let currentSet = currentRound * 6;

    for (; currentSet < setCurrentBonus().totalRounds; currentSet++) {
      const bbIcon = BattleAnim[(setCurrentBonus().data.battleBonusRounds[currentSet] as Round12345).points].bbIcon;

      eventManager.emit(EventTypes.BATTLE_BONUS_SET_ICON, currentSet - currentRound * 6, bbIcon);
    }

    this.battleBonusCounter = setCurrentBonus().currentRound;
    this.setState(SlotMachineState.IDLE);
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioHowl.play({ type: ISongs.Win_Loop });
      }
    });
    eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, this.startBuyFeature.bind(this));
    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));
    eventManager.addListener(EventTypes.START_BUY_FEATURE_ROUND, this.startBuyFeature.bind(this));
    eventManager.addListener(EventTypes.SET_ENDING_DISPLAY, this.setEndingDisplay.bind(this));

    eventManager.addListener(EventTypes.SET_SLOT_BUSY_DISABLE, () => {
      this.isSlotBusyCallback();
    });
  }

  private startBuyFeature(): void {
    let gameMode: GameMode;
    if (setIsHyperBattleBonus()) {
      gameMode = GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS;
    } else {
      gameMode = GameMode.BUY_FEATURE_BATTLE_BONUS;
    }
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: gameMode,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[gameMode],
    });
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      this.battleBonusCounter = 0;
      setGameMode(settings.mode);

      if (settings.mode === GameMode.REGULAR) {
        setCurrentFreeSpinsTotalWin(0);
        eventManager.emit(EventTypes.REMOVE_BATTLE_BNS_TITLE);
      }
    }
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(EventTypes.REMOVE_BATTLE_BNS_TITLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, setIsContinueAutoSpinsAfterFeature());
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isBattleBonusMode(settings.mode)) {
      const bonus = this.getFreeSpinBonus();

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');

      setCurrentBonus({ ...bonus, totalRounds: 0 });
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.REMOVE_BATTLE_BNS_TITLE);
      eventManager.emit(EventTypes.CREATE_BATTLE_BNS_TITLE, {
        text: i18n.t('battleBonusTitleText'),
        currentSet: 1,
      });

      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        eventManager.emit(EventTypes.BATTLE_START_ANIM_LOOP_START);

        let bonusTitle = 'battleBonus';
        if (this.isGold) {
          bonusTitle = 'HyperBattleBonus';
        }
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t(bonusTitle),
          description: i18n.t('battleBonusText'),
          btnText: i18n.t('startText'),
          vsText: i18n.t('vsText'),
          youText: i18n.t('youText'),
          rivalText: i18n.t('rivalText'),
          callback: () => {
            setCurrentBonus({
              ...bonus,
              isActive: true,
              totalRounds: setCurrentBonus().totalRounds,
              currentRound: 0,
            });
            BgmControl.stopBgm();
            eventManager.emit(EventTypes.BATTLE_START_ANIM_START, Math.floor(setCurrentBonus().totalRounds / 6) + 1);

            AudioHowl.fadeOut(BB_BANNER_FADEOUT_TIME, ISongs.BB_Banner);
            this.setState(SlotMachineState.IDLE);
          },
        });
      } else {
        eventManager.emit(EventTypes.BATTLE_START_ANIM_LOOP_START);
        eventManager.emit(EventTypes.BATTLE_START_ANIM_START, 1);
        setCurrentBonus({
          ...bonus,
          isActive: true,
          totalRounds: 0,
          currentRound: 0,
        });
        this.setState(SlotMachineState.IDLE);
      }
    }
    if (this.nextResult) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
    }
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.BATTLE_BONUS, // TODO
      reelPositions: [1, 1, 1],
      reelSetId: reelSets[GameMode.BATTLE_BONUS], // TODO
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
    eventManager.emit(EventTypes.START_FREE_SPINS);
    setTimeout(() => {
      if (setIsFreeRoundBonus() && setFreeRoundsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      } else if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      } else {
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      }
    }, FREE_SPINS_FADE_IN_DURATION);
  }

  private async endFreeSpins(): Promise<void> {
    // const res = await client.query<{
    //   userBonuses: UserBonus[];
    // }>({
    //   query: getUserBonuses,
    //   variables: { input: { id: setCurrentBonus().id } },
    //   fetchPolicy: 'network-only',
    // });
    // const { betId } = res.data.userBonuses[0];
    // const bet = await client.query<ISettledBet>({
    //   query: slotBetGql,
    //   variables: { input: { id: betId } },
    //   fetchPolicy: 'network-only',
    // });

    // const { reelPositions, reelSetId } = {
    //   reelPositions: bet.data.bet.result.reelPositions,
    //   reelSetId: bet.data.bet.reelSetId,
    // };
    eventManager.emit(EventTypes.END_FREE_SPINS);
    const reelPositions: number[] = [7, 7, 7];
    const reelSetId = '';

    // AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();

    if (this.nextResult) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
    }

    const battleNum = setCurrentBonus().totalRounds / 6;
    if (battleNum >= SP_ENDING_ROUND && setCurrentBonus().totalRounds === setCurrentBonus().rounds) {
      eventManager.emit(EventTypes.START_ENDING_FADE, reelPositions, reelSetId);
    } else {
      this.setEndingDisplay(reelPositions, reelSetId);
    }
    setBrokenGame(false);
  }

  private setEndingDisplay(reelPositions: number[], reelSetId: string): void {
    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
        reelPositions,
      });

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency())),
        );
      }, FREE_SPINS_FADE_IN_DURATION);
    };

    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });

    const battleNum = String(setCurrentBonus().totalRounds / 6);
    eventManager.emit(EventTypes.SET_SLOT_BUSY_DISABLE);
    const delay1 = Tween.createDelayAnimation(0);
    delay1.addOnComplete(() => {
      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
          totalWin: `${formatNumber(
            setCurrency(),
            normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          )} `,
          bonusStreak: '', // TODO bonusStreakOrdinal,
          preventDefaultDestroy: true,
          callback,
          title: i18n.t('battleBonusTotalWinTitle'),
          battleText: i18n.t('battle'),
          battleNum: battleNum,
        });
      } else {
        eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
          totalWin: `${formatNumber(
            setCurrency(),
            normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          )}`,
          bonusStreak: '', // TODO bonusStreakOrdinal,,
          preventDefaultDestroy: true,
          onInitCallback: () => delay.start(),
          title: i18n.t('battleBonusTotalWinTitle'),
          battleText: i18n.t('battle'),
          battleNum: battleNum,
        });
      }
    });
    delay1.start();
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(current: number): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_BATTLE_BNS_TITLE, current, true);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          if (isBattleBonusMode(setGameMode())) {
            this.updateFreeSpinsAmount(Math.floor((setCurrentBonus().totalRounds - 1) / 6) + 1);
          } else {
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            );
          }
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      if (isBattleBonusMode(setGameMode())) {
        const bonus = setCurrentBonus();

        if (setIsBattleStartAnim()) {
          return;
        }

        if (bonus.totalRounds % 6 !== 0) {
          bonus.currentRound += 1;
        } else {
          bonus.currentRound = 0;
        }

        if (setCurrentBonus().rounds > setCurrentBonus().totalRounds) {
          bonus.totalRounds += 1;
          setCurrentBonus(bonus);
        }

        if (setCurrentBonus().currentRound % 6 === 5) {
          eventManager.emit(EventTypes.BATTLE_BONUS_R6_INTRO_START);
        } else if (setCurrentBonus().currentRound % 6 != 0) {
          BgmControl.playBgm(BgmSoundTypes.BB1);
          eventManager.emit(EventTypes.BATTLE_BONUS_INTRO_START);
        }
      } else {
        eventManager.emit(EventTypes.START_SPIN_ANIMATION);
        this.skipAnimations();
        eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
        const spinAnimation = this.getSpinAnimation(!isBattleBonusMode(setGameMode()) && !!isTurboSpin);

        spinAnimation.start();
      }
      this.setState(SlotMachineState.SPIN);
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isFreeSpinsMode(setGameMode())) {
              this.updateFreeSpinsAmount(setCurrentBonus().currentRound);
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 2) {
          spinAnimation.addOnComplete(() => {
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin);
          });
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'PRE_LOADED';
    })?.userBonus;
  }

  public getReSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'SPECIAL_ROUND';
    })?.userBonus;
  }

  private async onCountUpEnd(): Promise<void> {
    // console.log('--onCountUpEnd1', performance.now());
    const freeSpinsBonus = this.getFreeSpinBonus();
    const reSpinsBonus = this.getReSpinBonus();
    const mode = setGameMode();
    if (freeSpinsBonus && !isFreeSpinsMode(mode)) {
      const winAmount = this.nextResult!.bet.result.winCoinAmount;
      setLastRegularWinAmount(winAmount);
      setCurrentFreeSpinsTotalWin(winAmount);
      setCurrentBonus({
        ...freeSpinsBonus,
        isActive: true,
        currentRound: 0,
        totalRounds: 0,
      });
      if (setIsFreeRoundBonus() && winAmount > 0) {
        setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
      }
      this.startFreeSpins();
      this.setState(SlotMachineState.IDLE);
    } else if (!freeSpinsBonus && isFreeSpinsMode(mode)) {
      const winAmount = this.nextResult!.bet.result.winCoinAmount;
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmount);
      if (setCurrentFreeSpinsTotalWin() > 0) {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      if (setIsFreeRoundBonus() && winAmount > 0) {
        setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      }
      eventManager.emit(EventTypes.HANDLE_UPDATE_BATTLE_BNS_GET, setCurrentFreeSpinsTotalWin(), false);
      // console.log('--onCountUpEnd2', performance.now());
      // this.setState(SlotMachineState.IDLE);
    } else if (reSpinsBonus) {
      if (this.hasWin()) {
        const dummy = Tween.createDelayAnimation(1000);
        dummy.addOnComplete(() => {
          eventManager.emit(EventTypes.CREATE_RESPIN_MESSAGE);
        });
        dummy.start();
      }
      const gameMode = setGameMode() + 1;
      setCurrentBonusId(reSpinsBonus.id);
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: gameMode,
        reelPositions: [0, 0, 0, 0, 0],
        reelSetId: reelSets[gameMode],
      });
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      if (this.nextResult?.bet.result.winCoinAmount && this.nextResult?.bet.result.winCoinAmount > 0) {
        setFreeRoundsTotalWin(setFreeRoundsTotalWin() + this.nextResult?.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      }

      this.setState(SlotMachineState.IDLE);
    } else {
      if (setIsFreeRoundBonus()) {
        if (isReSpinMode(mode)) {
          setIsGetUserBonuses(true);
          const activeBonuses = await getUserActiveBonuses();
          eventManager.emit(EventTypes.END_RE_SPINS, activeBonuses.data.userBonuses);
          setIsGetUserBonuses(false);
        }
        const winAmount = this.nextResult!.bet.result.winCoinAmount;
        if (winAmount > 0) {
          setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
          eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
        }
      }
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.REGULAR,
        reelPositions: [0, 0, 0, 0, 0],
        reelSetId: reelSets[GameMode.REGULAR],
      });
      setWinAmount(this.nextResult?.bet.result.winCoinAmount);
      setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }

      this.setState(SlotMachineState.IDLE);
    }
    if (this.nextResult) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
    }
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelSets[setGameMode()])!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationStartReelId(spinResult: Array<Icon>): number {
    setReelAnticipation(ReelAnticipation.NON);
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;

    const lineResult = this.slotConfig.lines.map((line) => line.map((v) => spinResult[v]));

    const anticipationLine: number[] = [];
    const anticipationSlotId: SlotId[] = [];

    ANTICIPATION_SYMBOLS_ID.forEach((symbolId, i) => {
      const count = ANTICIPATION_START_SYMBOLS_AMOUNT[i];

      lineResult.some((line, index) => {
        if (line[0].id === symbolId && line[2].id === symbolId) {
          setReelAnticipation(ReelAnticipation.BONUS);
          minReelId = Math.min(minReelId, count - 1);
          anticipationLine.push(index);
          anticipationSlotId.push(symbolId);
        }
      });
    });

    setAnticipationLine(anticipationLine);
    setAnticipationSlotId(anticipationSlotId);

    if (isReSpinMode(setGameMode())) {
      setReelAnticipation(ReelAnticipation.RESPIN);
      minReelId = Math.min(minReelId, ANTICIPATION_RESPIN_REEL);
    }

    const reelPos = setNextResult()?.bet.result.reelPositions;
    const reelSetCenter = setSlotConfig().reels.find((reels) => reels.id === setNextResult()?.bet.reelSet.id)!
      .layout[1];
    const reelSetCenterLong = [...reelSetCenter, ...reelSetCenter];

    let AnimationPtn = AnimationType.NON;
    let soundPhrase = undefined;

    const slotJ = this.calcStopSoundSymbolCount(spinResult, 1, GOLD_BONUS_SYMBOLS_ID);

    const slotK = this.calcStopSoundSymbolCount(spinResult, 1, RED_BONUS_SYMBOLS_ID);

    if (slotJ[1] === 4) {
      this.isGold = true;
    } else {
      this.isGold = false;
    }

    const userBonusId = this.getBattleBonusRounds(this.nextResult!);
    const bonusModeHigh =
      userBonusId != undefined &&
      (userBonusId[0].mode === 'mode_3' || userBonusId[0].mode === 'mode_4' || userBonusId[0].mode === 'mode_5');

    if (isBaseGameMode(setGameMode())) {
      if (
        isReSpinSlotID(reelSetCenterLong[reelPos![1] + reelSetCenter.length + 2]) &&
        setReelAnticipation() != ReelAnticipation.BONUS
      ) {
        if (getOutSideRespin(setNextResult()!.bet.id)) {
          setReelAnticipation(ReelAnticipation.RESPIN);
          minReelId = Math.min(minReelId, ANTICIPATION_RESPIN_REEL);
        }
        [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
          setNextResult()!.bet.id,
          baseGameSevenNotRespinBelowReel[0],
        );
      }

      if (
        isReSpinSlotID(reelSetCenterLong[reelPos![1] + reelSetCenter.length - 1]) ||
        isReSpinSlotID(reelSetCenterLong[reelPos![1] + reelSetCenter.length]) ||
        isReSpinSlotID(reelSetCenterLong[reelPos![1] + reelSetCenter.length + 1])
      ) {
        if (getInSideRespin(setNextResult()!.bet.id) && setReelAnticipation() != ReelAnticipation.BONUS) {
          setReelAnticipation(ReelAnticipation.RESPIN);
          minReelId = Math.min(minReelId, ANTICIPATION_RESPIN_REEL);
        }

        if (slotJ[2] === 2 || slotK[2] === 2) {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            baseGameSevenReadyRespinStopOnReel[0],
          );
        } else {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            baseGameSevenNotRespinOnReel[0],
          );
        }
      }

      if (slotJ[1] === 4 || slotK[1] === 3) {
        if (bonusModeHigh) {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            baseGameSevenWinMode345[0],
          );
          // console.log('baseGameSevenWinMode345');
        } else {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            baseGameSevenWinMode12[0],
          );
          // console.log('baseGameSevenWinMode12');
        }
      }

      if (slotJ[2] === 2 && slotJ[1] === 0) {
        if (
          reelSetCenterLong[reelPos![1] + reelSetCenter.length - 1] === SlotId.J ||
          reelSetCenterLong[reelPos![1] + reelSetCenter.length] === SlotId.J ||
          reelSetCenterLong[reelPos![1] + reelSetCenter.length + 1] === SlotId.J ||
          reelSetCenterLong[reelPos![1] + reelSetCenter.length + 2] === SlotId.J
        ) {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            baseGameGoldSevenReadyStopBelowReel[0],
          );
        }
      } else if (slotK[2] === 2 && slotK[1] === 0) {
        if (
          reelSetCenterLong[reelPos![1] + reelSetCenter.length - 1] === SlotId.K ||
          reelSetCenterLong[reelPos![1] + reelSetCenter.length] === SlotId.K ||
          reelSetCenterLong[reelPos![1] + reelSetCenter.length + 1] === SlotId.K ||
          reelSetCenterLong[reelPos![1] + reelSetCenter.length + 2] === SlotId.K
        ) {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            baseGameRedSevenReadyStopBelowReel[0],
          );
        }
      }
    } else if (isReSpinMode(setGameMode())) {
      if (setGameMode() === GameMode.RESPIN_4 && this.getFreeSpinBonus()) {
        if ((slotJ[1] === 4 || slotK[1] === 3) && bonusModeHigh) {
          [AnimationPtn, soundPhrase] = getAnimationSelectionTableLot(
            setNextResult()!.bet.id,
            respinLevel4SevenWinMode345[0],
          );
        }
      }
    }

    if (AnimationPtn != AnimationType.NON) {
      if (AnimationPtn === AnimationType.PHOENIX) {
        eventManager.emit(EventTypes.PHOENIX_START);
      } else {
        eventManager.emit(EventTypes.CHARA_WINDOW_ANIMATION, AnimationPtn, soundPhrase);
      }
    }
    return minReelId;
  }

  private getStopSoundSymbolCount(spinResult: Array<Icon>): Array<number> {
    let reelStopSound = [0, 0, 0];
    if (isBaseGameMode(setGameMode()) || isBuyFeatureMode(setGameMode()) || isReSpinMode(setGameMode())) {
      reelStopSound = this.calcStopSoundSymbolCount(spinResult, 1, BONUS_SYMBOLS_ID);
    }
    return reelStopSound;
  }

  private calcStopSoundSymbolCount(spinResult: Array<Icon>, cal: number, symbol: Array<SlotId>[]): Array<number> {
    const iSoundCnt = [0, 0, 0];

    this.slotConfig.lines.forEach((line) => {
      symbol.forEach((slot) => {
        for (let x = 0; x < slot.length; x++) {
          const index = getStopReel(x);
          if (spinResult[line[index]].id === slot[index]) {
            if (index === 0) {
              iSoundCnt[index] = cal;
            } else if (index === 1) {
              if (slot[index][0] === SlotId.J) {
                setHyperBattleBonus(true);
                iSoundCnt[index] = iSoundCnt[2] + 2;
              } else {
                setHyperBattleBonus(false);
                iSoundCnt[index] = iSoundCnt[2] + 1;
              }
            } else {
              iSoundCnt[index] = iSoundCnt[0] + 1;
            }
          } else {
            break;
          }
        }
      });
    });
    return iSoundCnt;
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    if (isBattleBonusMode(setGameMode())) {
      this.nextResult = result;
      const battleBonusRound = setBattleBonusRounds();
      const battleCounter = setCurrentBonus().totalRounds - 1;

      eventManager.emit(EventTypes.BATTLE_BONUS_WIN_AMOUNT, result, battleBonusRound[battleCounter]);
      this.battleBonusCounter += 1;
    } else {
      const spinResult = getSpinResult3x3({
        reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
        reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
        icons: setSlotConfig().icons,
      });
      result.bet.result.spinResult = spinResult;
      setPrevReelsPosition(result.bet.result.reelPositions.slice(0, REELS_AMOUNT));
      this.nextResult = result;
      setNextResult(result);

      const userBonusId = this.getBattleBonusRounds(result);
      if (userBonusId != undefined) {
        setBattleBonusRounds(userBonusId);
      }
    }
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
  }

  private getBattleBonusRounds(result: ISettledBet): RoundType[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return (
      result.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward',
      ) as BetBonusReward
    )?.userBonus?.data!.battleBonusRounds!;
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(this.nextResult!.bet.result.spinResult);
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length - this.reelsContainer.reels[x].position + y - 1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;

    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isFreeSpinsMode(setGameMode()) ? false : state === 0);
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  public hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
        setIsFreeSpinsWin() ||
        setIsContinueAutoSpinsAfterFeature() ||
        isReSpinMode(setGameMode()),
    );

    if (state === SlotMachineState.IDLE) {
      if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().totalRounds) {
      } else {
        this.isSlotBusyCallback();
      }

      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isReSpinMode(setGameMode())) {
        if (!setCurrentBonus().isActive) {
          this.skipAnimations();
          if(!setStressful().show){
          eventManager.emit(EventTypes.RESPIN_START);
          setRespinBusy(true);
          setTimeout(() =>{
            if(!setStressful().show){
                eventManager.emit(EventTypes.RESPIN);
            }
          }, RESPIN_WAIT_TIME);
          let waitTime = RESPIN1_QUICK_START_WAITE;
          if (setGameMode() == GameMode.RESPIN_1) {
            waitTime = RESPIN1_QUICK_START_WAITE;
          } else {
            waitTime = RESPIN_OTHER_QUICK_START_WAITE;
          }
          setTimeout(() => setRespinBusy(false), waitTime);
          }
        }
      } else if (isFreeSpinsMode(setGameMode())) {
        this.isSpinInProgressCallback();
        if (setCurrentBonus().isActive && setCurrentBonus().rounds === setCurrentBonus().totalRounds) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          const totalRounds = setCurrentBonus().totalRounds;

          const delayTime = 0;
          setTimeout(() => {
            if (totalRounds != setCurrentBonus().totalRounds) {
              return;
            }

            if (setBrokenGame() && setCurrentBonus().totalRounds === 0) {
              eventManager.emit(EventTypes.BATTLE_START_ANIM_LOOP_START);
              eventManager.emit(EventTypes.BATTLE_START_ANIM_START, Math.floor(setCurrentBonus().totalRounds / 6) + 1);
            }

            if (setCurrentBonus().totalRounds % 6 === 0) {
              eventManager.emit(EventTypes.BATTLE_BONUS_ROUND1);
              eventManager.emit(
                EventTypes.HANDLE_UPDATE_BATTLE_BNS_TITLE,
                Math.floor(setCurrentBonus().totalRounds / 6) + 1,
                false,
              );
              BgmControl.stopBgm();

              if (setCurrentBonus().totalRounds != 0) {
                eventManager.emit(EventTypes.BATTLE_START_ANIM_LOOP_START);
                eventManager.emit(
                  EventTypes.BATTLE_START_ANIM_START,
                  Math.floor(setCurrentBonus().totalRounds / 6) + 1,
                );
              }
            } else {
              eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND);
            }
          }, delayTime);
        }
      }

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      // console.log('--JINGLE', performance.now());
      if (this.getFreeSpinBonus()) {
        let triggerPhrase = ISongs.BB_Trigger;
        let triggerDelay = BB_TRIGGER_DELAY;
        if (setHyperBattleBonus()) {
          triggerPhrase = ISongs.HBB_Trigger;
          triggerDelay = HBB_TRIGGER_DELAY;
        }
        const jingleDelay = Tween.createDelayAnimation(triggerDelay);
        jingleDelay.addOnStart(() => {});
        jingleDelay.addOnComplete(() => {
          if (this.getFreeSpinBonus()) {
            eventManager.emit(EventTypes.TRIGGER_JINGLE);
            AudioHowl.play({ type: triggerPhrase });
          }
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else if (this.hasWin()) {
        const jingleDelay = Tween.createDelayAnimation(
          // mappedAudioSprites[ISongs.FSTrigger].duration,
          WIN_ANIM_START_DELAY,
        );
        jingleDelay.addOnStart(() => {
          if (this.getFreeSpinBonus()) {
            eventManager.emit(EventTypes.TRIGGER_JINGLE);
            let triggerPhrase = ISongs.BB_Trigger;
            if (setHyperBattleBonus()) {
              triggerPhrase = ISongs.HBB_Trigger;
            }
            AudioHowl.play({ type: triggerPhrase });
          }
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      // console.log('--WINNING', performance.now());

      if (this.hasWin() || this.getFreeSpinBonus()) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
      } else {
        this.onCountUpEnd();
      }
    }
  }
}

export default SlotMachine;
